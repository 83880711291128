import SERVICE1 from '../assets/images/service/service/tattoo_Design.jpg'
import SERVICE2 from '../assets/images/service/service/tattoo_redesign.jpg'
import SERVICE3 from '../assets/images/service/service/cover_up_tattoo (1).jpg'
import SERVICE4 from '../assets/images/service/service/Piercings.jpg'
import SERVICE5 from '../assets/images/service/service/tattoo_academy (2).png'
import FLASHTATTOO1 from '../assets/images/flash_tattoo/Moon Tattoos.png';
import FLASHTATTOO2 from '../assets/images/flash_tattoo/Animal Tattoos.png';
import FLASHTATTOO3 from '../assets/images/flash_tattoo/Calligraphy Tattoos.png';
import FLASHTATTOO4 from '../assets/images/flash_tattoo/Family Tattoos.png';
import FLASHTATTOO5 from '../assets/images/flash_tattoo/Heart Tattoos.png';
import FLASHTATTOO6 from '../assets/images/flash_tattoo/Religious Tattoos.png';
import FLASHTATTOO7 from '../assets/images/flash_tattoo/One LIne Tattoos.png';
import FLASHTATTOO8 from '../assets/images/flash_tattoo/Travel Tattoos.png';
import FLASHTATTOO9 from '../assets/images/flash_tattoo/Zodic Tattoos.png';
import TATTOO1 from '../assets/images/our_work/img/tattoo/img_0000_IMG_9591.jpg';
import TATTOO2 from '../assets/images/our_work/img/tattoo/img_0001_IMG_9562.jpg';
import TATTOO3 from '../assets/images/our_work/img/tattoo/img_0002_IMG_9491.jpg';
import TATTOO4 from '../assets/images/our_work/img/tattoo/img_0003_IMG_9076.jpg';
import TATTOO5 from '../assets/images/our_work/img/tattoo/img_0004_IMG_8753.jpg';
import TATTOO6 from '../assets/images/our_work/img/tattoo/img_0005_IMG_8574.jpg';
import TATTOO7 from '../assets/images/our_work/img/tattoo/img_0006_IMG_8386.jpg';
import TATTOO8 from '../assets/images/our_work/img/tattoo/img_0007_IMG_8288.jpg';
import TATTOO9 from '../assets/images/our_work/img/tattoo/img_0008_IMG_8160.jpg';
import TATTOO10 from '../assets/images/our_work/img/tattoo/img_0009_IMG_8068.jpg';
import TATTOO11 from '../assets/images/our_work/img/tattoo/img_0010_IMG_7969.jpg';
import TATTOO12 from '../assets/images/our_work/img/tattoo/img_0011_IMG_7939.jpg';
import TATTOO13 from '../assets/images/our_work/img/tattoo/img_0012_IMG_7747.jpg';
import TATTOO14 from '../assets/images/our_work/img/tattoo/img_0013_IMG_7731.jpg';
import TATTOO15 from '../assets/images/our_work/img/tattoo/img_0014_IMG_7610.jpg';
import TATTOO16 from '../assets/images/our_work/img/tattoo/img_0015_IMG_7429.jpg';
import TATTOO17 from '../assets/images/our_work/img/tattoo/img_0016_IMG_7290.jpg';
import TATTOO18 from '../assets/images/our_work/img/tattoo/img_0017_IMG_7077.jpg';
import TATTOO19 from '../assets/images/our_work/img/tattoo/img_0018_IMG_7055.jpg';
import TATTOO20 from '../assets/images/our_work/img/tattoo/img_0019_IMG_6944.jpg';
import TATTOO21 from '../assets/images/our_work/img/tattoo/img_0020_IMG_6874.jpg';
import TATTOO22 from '../assets/images/our_work/img/tattoo/img_0021_IMG_6536.jpg';
import TATTOO23 from '../assets/images/our_work/img/tattoo/img_0022_IMG_6517.jpg';
import TATTOO24 from '../assets/images/our_work/img/tattoo/img_0023_IMG_6451.jpg';
import TATTOO25 from '../assets/images/our_work/img/tattoo/img_0024_IMG_6286.jpg';
import TATTOO26 from '../assets/images/our_work/img/tattoo/img_0025_IMG_6279.jpg';
import TATTOO27 from '../assets/images/our_work/img/tattoo/img_0026_IMG_6206.jpg';
import TATTOO28 from '../assets/images/our_work/img/tattoo/img_0027_IMG_6144.jpg';
import TATTOO29 from '../assets/images/our_work/img/tattoo/img_0028_IMG_5979.jpg';
import TATTOO30 from '../assets/images/our_work/img/tattoo/img_0029_IMG_5833.jpg';
import TATTOO31 from '../assets/images/our_work/img/tattoo/img_0030_IMG_5320.jpg';
import TATTOO32 from '../assets/images/our_work/img/tattoo/img_0031_IMG_4846.jpg';
import TATTOO33 from '../assets/images/our_work/img/tattoo/img_0032_IMG_3601.jpg';
import TATTOO34 from '../assets/images/our_work/img/tattoo/img_0033_IMG_3208.jpg';
import TATTOO35 from '../assets/images/our_work/img/tattoo/img_0034_IMG_3002.jpg';
import TATTOO36 from '../assets/images/our_work/img/tattoo/img_0035_IMG_2928.jpg';
import TATTOO37 from '../assets/images/our_work/img/tattoo/img_0036_IMG_2792.jpg';
import TATTOO38 from '../assets/images/our_work/img/tattoo/img_0037_IMG_2769.jpg';
import TATTOO39 from '../assets/images/our_work/img/tattoo/img_0038_IMG_2741.jpg';
import TATTOO40 from '../assets/images/our_work/img/tattoo/img_0039_IMG_2732.jpg';
import TATTOO41 from '../assets/images/our_work/img/tattoo/img_0040_IMG_2723.jpg';
import TATTOO42 from '../assets/images/our_work/img/tattoo/img_0041_IMG_2709.jpg';
import TATTOO43 from '../assets/images/our_work/img/tattoo/img_0042_IMG_2694.jpg';
import TATTOO44 from '../assets/images/our_work/img/tattoo/img_0043_IMG_2665.jpg';
import TATTOO45 from '../assets/images/our_work/img/tattoo/img_0044_IMG_2622.jpg';
import TATTOO46 from '../assets/images/our_work/img/tattoo/img_0045_IMG_2620.jpg';
import TATTOO47 from '../assets/images/our_work/img/tattoo/img_0046_IMG_2603.jpg';
import TATTOO48 from '../assets/images/our_work/img/tattoo/img_0047_IMG_2581.jpg';
import TATTOO49 from '../assets/images/our_work/img/tattoo/img_0048_IMG_2579.jpg';
import TATTOO50 from '../assets/images/our_work/img/tattoo/img_0049_IMG_2573.jpg';
import TATTOO51 from '../assets/images/our_work/img/tattoo/img_0050_IMG_2569.jpg';
import TATTOO52 from '../assets/images/our_work/img/tattoo/img_0051_IMG_2549.jpg';
import TATTOO53 from '../assets/images/our_work/img/tattoo/img_0052_IMG_2548.jpg';
import TATTOO54 from '../assets/images/our_work/img/tattoo/img_0053_IMG_2546.jpg';
import TATTOO55 from '../assets/images/our_work/img/tattoo/img_0054_IMG_2543.jpg';
import TATTOO56 from '../assets/images/our_work/img/tattoo/img_0055_IMG_2541.jpg';
import TATTOO57 from '../assets/images/our_work/img/tattoo/img_0056_IMG_2541 (1).jpg';
import TATTOO58 from '../assets/images/our_work/img/tattoo/img_0057_IMG_2537.jpg';
import TATTOO59 from '../assets/images/our_work/img/tattoo/img_0058_IMG_2498.jpg';
import TATTOO60 from '../assets/images/our_work/img/tattoo/img_0059_IMG_2491.jpg';
import TATTOO61 from '../assets/images/our_work/img/tattoo/img_0060_IMG_2479.jpg';
import TATTOO62 from '../assets/images/our_work/img/tattoo/img_0061_IMG_2453.jpg';
import TATTOO63 from '../assets/images/our_work/img/tattoo/img_0062_IMG_2442.jpg';
import TATTOO64 from '../assets/images/our_work/img/tattoo/img_0063_IMG_2432.jpg';
import TATTOO65 from '../assets/images/our_work/img/tattoo/img_0064_IMG_2387.jpg';
import TATTOO66 from '../assets/images/our_work/img/tattoo/img_0065_IMG_2363.jpg';
import TATTOO67 from '../assets/images/our_work/img/tattoo/img_0066_IMG_2322.jpg';
import TATTOO68 from '../assets/images/our_work/img/tattoo/img_0067_IMG_2319.jpg';
import TATTOO69 from '../assets/images/our_work/img/tattoo/img_0068_IMG_2315.jpg';
import TATTOO70 from '../assets/images/our_work/img/tattoo/img_0069_IMG_2314.jpg';
import TATTOO71 from '../assets/images/our_work/img/tattoo/img_0070_IMG_2300.jpg';
import TATTOO72 from '../assets/images/our_work/img/tattoo/img_0071_IMG_2287.jpg';
import TATTOO73 from '../assets/images/our_work/img/tattoo/img_0072_IMG_2273.jpg';
import TATTOO74 from '../assets/images/our_work/img/tattoo/img_0073_IMG_2221.jpg';
import TATTOO75 from '../assets/images/our_work/img/tattoo/img_0074_IMG_2218.jpg';
import TATTOO76 from '../assets/images/our_work/img/tattoo/img_0075_IMG_2203.jpg';
import TATTOO77 from '../assets/images/our_work/img/tattoo/img_0076_IMG_2200.jpg';
import TATTOO78 from '../assets/images/our_work/img/tattoo/img_0077_IMG_2197.jpg';
import TATTOO79 from '../assets/images/our_work/img/tattoo/img_0078_IMG_2164.jpg';
import TATTOO80 from '../assets/images/our_work/img/tattoo/img_0079_IMG_2154.jpg';
import TATTOO81 from '../assets/images/our_work/img/tattoo/img_0080_IMG_2144.jpg';
import TATTOO82 from '../assets/images/our_work/img/tattoo/img_0081_IMG_2143.jpg';
import TATTOO83 from '../assets/images/our_work/img/tattoo/img_0082_IMG_2130.jpg';
import TATTOO84 from '../assets/images/our_work/img/tattoo/img_0083_IMG_2128.jpg';
import TATTOO85 from '../assets/images/our_work/img/tattoo/img_0084_IMG_2099.jpg';
import TATTOO86 from '../assets/images/our_work/img/tattoo/img_0085_IMG_2098.jpg';
import TATTOO87 from '../assets/images/our_work/img/tattoo/img_0086_IMG_2080.jpg';
import TATTOO88 from '../assets/images/our_work/img/tattoo/img_0087_IMG_2058.jpg';
import TATTOO89 from '../assets/images/our_work/img/tattoo/img_0088_IMG_2032.jpg';
import TATTOO90 from '../assets/images/our_work/img/tattoo/img_0089_IMG_2027.jpg';
import TATTOO91 from '../assets/images/our_work/img/tattoo/img_0090_IMG_2022.jpg';
import TATTOO92 from '../assets/images/our_work/img/tattoo/img_0091_IMG_2011.jpg';
import TATTOO93 from '../assets/images/our_work/img/tattoo/img_0092_IMG_2004.jpg';
import TATTOO94 from '../assets/images/our_work/img/tattoo/img_0093_IMG_2002.jpg';
import TATTOO95 from '../assets/images/our_work/img/tattoo/img_0094_IMG_1989.jpg';
import TATTOO96 from '../assets/images/our_work/img/tattoo/img_0095_IMG_1984.jpg';
import TATTOO97 from '../assets/images/our_work/img/tattoo/img_0096_IMG_1975.jpg';
import TATTOO98 from '../assets/images/our_work/img/tattoo/img_0097_IMG_1969.jpg';
import TATTOO99 from '../assets/images/our_work/img/tattoo/img_0098_IMG_1963.jpg';
import TATTOO100 from '../assets/images/our_work/img/tattoo/img_0099_IMG_1960.jpg';
import TATTOO101 from '../assets/images/our_work/img/tattoo/img_0100_IMG_1957.jpg';
import TATTOO102 from '../assets/images/our_work/img/tattoo/img_0101_IMG_1906.jpg';
import TATTOO103 from '../assets/images/our_work/img/tattoo/img_0102_IMG_1828.jpg';
import TATTOO104 from '../assets/images/our_work/img/tattoo/img_0103_IMG_1819.jpg';
import TATTOO105 from '../assets/images/our_work/img/tattoo/img_0104_IMG_1818.jpg';
import TATTOO106 from '../assets/images/our_work/img/tattoo/img_0105_IMG_1781.jpg';
import TATTOO107 from '../assets/images/our_work/img/tattoo/img_0106_IMG_1778.jpg';
import TATTOO108 from '../assets/images/our_work/img/tattoo/img_0107_IMG_1736.jpg';
import TATTOO109 from '../assets/images/our_work/img/tattoo/img_0108_IMG_1730.jpg';
import TATTOO110 from '../assets/images/our_work/img/tattoo/img_0109_IMG_1729.jpg';
import TATTOO111 from '../assets/images/our_work/img/tattoo/img_0110_IMG_1713.jpg';
import TATTOO112 from '../assets/images/our_work/img/tattoo/img_0111_IMG_1671.jpg';
import TATTOO113 from '../assets/images/our_work/img/tattoo/img_0112_IMG_1544.jpg';
import TATTOO114 from '../assets/images/our_work/img/tattoo/img_0113_IMG_1055.jpg';
import TATTOO115 from '../assets/images/our_work/img/tattoo/img_0114_IMG_9648.jpg';
import TATTOO116 from '../assets/images/our_work/img/tattoo/img_0115_IMG_1054.jpg';
import TATTOO121 from '../assets/images/our_work/img/tattoo/img_0116_IMG_0923.jpg';
import TATTOO122 from '../assets/images/our_work/img/tattoo/img_0117_IMG_0724.jpg';
import TATTOO123 from '../assets/images/our_work/img/tattoo/img_0118_IMG_0564.jpg';
import TATTOO124 from '../assets/images/our_work/img/tattoo/img_0119_IMG_0379.jpg';
import TATTOO125 from '../assets/images/our_work/img/tattoo/img_0120_IMG_0291.jpg';
import TATTOO126 from '../assets/images/our_work/img/tattoo/img_0121_IMG_0289.jpg';
import TATTOO127 from '../assets/images/our_work/img/tattoo/img_0122_IMG_0284.jpg';
import TATTOO128 from '../assets/images/our_work/img/tattoo/img_0123_IMG_0102.jpg';
import TATTOO129 from '../assets/images/our_work/img/tattoo/img_0124_IMG_0049.jpg';
import TATTOO130 from '../assets/images/our_work/img/tattoo/img_0125_IMG_0046.jpg';
import TATTOO131 from '../assets/images/our_work/img/tattoo/img_0126_IMG_0031.jpg';
import TATTOO132 from '../assets/images/our_work/img/tattoo/img_0127_IMG_0029.jpg';
import TATTOO133 from '../assets/images/our_work/img/tattoo/img_0128_IMG_0026.jpg';
import TATTOO134 from '../assets/images/our_work/img/tattoo/img_0129_IMG_0006.jpg';
import TATTOO135 from '../assets/images/our_work/img/tattoo/img_0130_F2CFFD81-8194-4DFC-B104-856785518CC0.jpg';
import TATTOO136 from '../assets/images/our_work/img/tattoo/img_0131_EBE5594B-9CB1-42BD-BD7A-644991675F78.jpg';
import TATTOO137 from '../assets/images/our_work/img/tattoo/img_0132_4889F851-B724-46E7-886C-047A132DA53C.jpg';
import TATTOO138 from '../assets/images/our_work/img/tattoo/img_0133_1419AC50-E1EC-41F2-8B13-019EDC0732D5.jpg';
import TATTOO139 from '../assets/images/our_work/img/tattoo/img_0134_963E6AD3-2BEF-4494-8AD4-13128EEDE6C3.jpg';
import TATTOO140 from '../assets/images/our_work/img/tattoo/img_0135_94A21B1A-6082-4454-AE93-DB04786B76D6.jpg';
import TATTOO141 from '../assets/images/our_work/img/tattoo/img_0136_17AF65F7-1510-4F0F-905A-530C1E5CDE06.jpg';
import TATTOO142 from '../assets/images/our_work/img/tattoo/img_0137_9e076474-85ea-4185-92d1-c03f0c4c3f70.jpg';
import TATTOO143 from '../assets/images/our_work/img/tattoo/img_0139_1baa3a05-a992-4d20-a01f-4af52b4d32b2.jpg';
import TATTOO144 from '../assets/images/our_work/img/tattoo/img6.jpg';

import TATTOO145 from '../assets/images/our_work/img/Piercings/img_0012_IMG_8805.jpg';
import TATTOO146 from '../assets/images/our_work/img/Piercings/img_0013_IMG_8010.jpg';
import TATTOO147 from '../assets/images/our_work/img/Piercings/img_0014_IMG_7393.jpg';
import TATTOO148 from '../assets/images/our_work/img/Piercings/img_0015_IMG_7052.jpg';
import TATTOO175 from '../assets/images/our_work/img/Piercings/img_0016_IMG_6772.jpg';


import TATTOO149 from '../assets/images/our_work/img/Piercings/img_0017_IMG_6737.jpg';
import TATTOO150 from '../assets/images/our_work/img/Piercings/img_0018_IMG_6200.jpg';
import TATTOO151 from '../assets/images/our_work/img/Piercings/img_0019_IMG_5874.jpg';
import TATTOO152 from '../assets/images/our_work/img/Piercings/img_0020_IMG_3488.jpg';
import TATTOO153 from '../assets/images/our_work/img/Piercings/img_0021_IMG_3486.jpg';
import TATTOO154 from '../assets/images/our_work/img/Piercings/img_0022_IMG_3095.jpg';
import TATTOO155 from '../assets/images/our_work/img/Piercings/img_0023_IMG_2951.jpg';
import TATTOO156 from '../assets/images/our_work/img/Piercings/img_0024_IMG_2899.jpg';
import TATTOO157 from '../assets/images/our_work/img/Piercings/img_0025_IMG_2762.jpg';
import TATTOO158 from '../assets/images/our_work/img/Piercings/img_0026_IMG_2752.jpg';
import TATTOO159 from '../assets/images/our_work/img/Piercings/img_0027_IMG_2367.jpg';
import TATTOO160 from '../assets/images/our_work/img/Piercings/img_0028_IMG_1519.jpg';
import TATTOO161 from '../assets/images/our_work/img/Piercings/img_0029_IMG_1306.jpg';
import TATTOO162 from '../assets/images/our_work/img/Piercings/img_0030_IMG_1205.jpg';
import TATTOO163 from '../assets/images/our_work/img/Piercings/img_0031_IMG_1157.jpg';
import TATTOO164 from '../assets/images/our_work/img/Piercings/img_0032_IMG_1141.jpg';
import TATTOO165 from '../assets/images/our_work/img/Piercings/img_0033_IMG_0979.jpg';
import TATTOO166 from '../assets/images/our_work/img/Piercings/img_0034_IMG_0976.jpg';
import TATTOO167 from '../assets/images/our_work/img/Piercings/img_0035_IMG_0954.jpg';
import TATTOO176 from '../assets/images/our_work/img/Piercings/img_0036_IMG_0951.jpg';


import TATTOO168 from '../assets/images/our_work/img/Piercings/img_0037_IMG_0842.jpg';
import TATTOO169 from '../assets/images/our_work/img/Piercings/img_0038_IMG_0654.jpg';
import TATTOO170 from '../assets/images/our_work/img/Piercings/img_0039_IMG_0629.jpg';
import TATTOO171 from '../assets/images/our_work/img/Piercings/img_0040_IMG_0513.jpg';
import TATTOO172 from '../assets/images/our_work/img/Piercings/img_0041_IMG_0481.jpg';
import TATTOO173 from '../assets/images/our_work/img/Piercings/img_0042_C40F0F3D-4C1D-4FF3-BACD-AE735DE8B289.jpg';
import TATTOO174 from '../assets/images/our_work/img/Piercings/img_0043_A55AE7F5-22BC-4DD7-A562-00C79A71B1E6.jpg';

export const servicesData = [
  {
    name: 'Tattoo Design',
    description: [
      'Robins Tattoo Studio in Ahmedabad believes tattoos are a profound expression of life, personality, and individuality. We are dedicated to guiding you through this transformative journey, ensuring your satisfaction and happiness. Our commitment lies in creating custom tattoo designs that resonate with your unique vision, making them a permanent part of your identity.',
      'At Robins Tattoo Studio, we prioritize your preferences and provide meticulous attention to detail. We stand out for our bespoke designs and exceptional service. Trust us to bring your ideas to life with skill and passion. Let Robins Tattoo Studio be your choice for a truly personalized tattoo experience.'
    ],
    image: SERVICE1
  },
  {
    name: 'Tattoo Redesign',
    description: [
      'At our tattoo redesign service, we specialize in transforming outdated, faded, or poorly crafted designs to better reflect our clients\' developing tastes and personal identities. Whether updating an old tattoo to align with your current personality or enhancing it with improved techniques and artistic skills, we ensure each redesign represents personal growth and transformation.',
      'Our professional tattoo artists skillfully craft intricate and appealing designs that breathe new life into existing tattoos, turning them into meaningful expressions of individuality. Trust our professional tattoo artists to deliver excellence in every detail, crafting a tattoo that resonates with your unique journey and personality.'
    ],
    image: SERVICE2
  },
  {
    name: 'Cover Up Tattoo',
    description: [
      'Are you stuck with a tattoo that no longer reflects who you’ve become? At Robins Tattoo Studio, you dont have to live with tattoo regret. We specialize in coverup tattoos, transforming your old or unwanted ink into a new masterpiece.',
      'Our expert artists have substantial experience and a deep understanding of shading, color theory, and design creation. We provide valuable insights into the best designs, styles, and colors to effectively cover your existing tattoo. Let us give you a complete tattoo makeover that aligns with your current personality and style. Visit us for a consultation and discover how we can turn your old tattoo into something you’ll love again.',
    ],
    image: SERVICE3
  },
  {
    name: 'Piercings',
    description: [
      'At Robins Tattoo Studio, we honor the ancient tradition of body piercing. People usually get body piercings to adorn their bodies, enhance their appearance, stand out from the crowd, and express themselves. Our professional artists have the deep knowledge and tools to give you the most comfortable piercing experience.',
      'Your safety is our top priority. We use disposable, sterile tools and safe piercing methods to ensure the highest hygiene standards. Our team is dedicated to making sure that every client leaves with a piercing they truly love, enhancing their appearance. Trust Robins Tattoo Studio for a safe, satisfying, and exceptional piercing experience.'
    ],
    image: SERVICE4
  },
  {
    name: 'Tattoo Academy',
    description: [
      'At Robins Tattoo Studio, our Tattoo Academy offers a comprehensive education for aspiring tattoo artists. We provide hands-on training with experienced professionals, covering essential techniques, hygiene practices, and artistic skills. Our curriculum includes in-depth sessions on design fundamentals, color theory, and skin anatomy.',
      'Students gain practical experience through real-life projects, under expert guidance, ensuring they develop a strong portfolio. We emphasize creativity, professionalism, and safety, preparing graduates for a successful career in the tattoo industry. Whether you are a beginner or looking to enhance your skills, our academy equips you with the knowledge and confidence to excel in this dynamic field.'
    ],
    image: SERVICE5
  }
];

export const tattooData = [
  { id: 1, title: 'Moon Tattoos', image: FLASHTATTOO1,price:'Rs:1500' },
  { id: 2, title: 'Animal Tattoos', image: FLASHTATTOO2,price:'Rs:1500' },
  { id: 3, title: 'Calligraphy Tattoos', image: FLASHTATTOO3,price:'Rs:1500' },
  { id: 4, title: 'Family Tattoos', image: FLASHTATTOO4,price:'Rs:1500' },
  { id: 5, title: 'Heart Tattoos', image: FLASHTATTOO5,price:'Rs:1500' },
  { id: 6, title: 'Religious Tattoos', image: FLASHTATTOO6,price:'Rs:1500' },
  { id: 7, title: 'One Line Tattoos', image: FLASHTATTOO7,price:'Rs:1500' },
  { id: 8, title: 'Travel Tattoos', image: FLASHTATTOO8,price:'Rs:1500' },
  { id: 9, title: 'Zodic Tattoos', image: FLASHTATTOO9,price:'Rs:1500' },

];

export const latesttattoos = [
  { id: 1, image: TATTOO1 },
  { id: 2, image: TATTOO2 },
  { id: 3, image: TATTOO3 },
  { id: 4, image: TATTOO4 },
  { id: 5, image: TATTOO5 },
  { id: 6, image: TATTOO6 },
  { id: 7, image: TATTOO7 },
  { id: 8, image: TATTOO8 },
  { id: 9, image: TATTOO9 },
  { id: 10, image: TATTOO10 },
  { id: 11, image: TATTOO11 },
  { id: 12, image: TATTOO12 },
  { id: 13, image: TATTOO13 },
  { id: 14, image: TATTOO14 },
  { id: 15, image: TATTOO15 },
  { id: 16, image: TATTOO16 },
  { id: 17, image: TATTOO17 },
  { id: 18, image: TATTOO18 },
  { id: 19, image: TATTOO19 },
  { id: 20, image: TATTOO20 },
  { id: 21, image: TATTOO21 },
  { id: 22, image: TATTOO22 },
  { id: 23, image: TATTOO23 },
  { id: 24, image: TATTOO24 },
  { id: 25, image: TATTOO25 },
  { id: 26, image: TATTOO26 },
  { id: 27, image: TATTOO27 },
  { id: 28, image: TATTOO28 },
  { id: 29, image: TATTOO29 },
  { id: 30, image: TATTOO30 },
  { id: 31, image: TATTOO31 },
  { id: 32, image: TATTOO32 },
  { id: 33, image: TATTOO33 },
  { id: 34, image: TATTOO34 },
  { id: 35, image: TATTOO35 },
  { id: 36, image: TATTOO36 },
  { id: 37, image: TATTOO37 },
  { id: 38, image: TATTOO38 },
  { id: 39, image: TATTOO39 },
  { id: 40, image: TATTOO40 },
  { id: 41, image: TATTOO41 },
  { id: 42, image: TATTOO42 },
  { id: 43, image: TATTOO43 },
  { id: 44, image: TATTOO44 },
  { id: 45, image: TATTOO45 },
  { id: 46, image: TATTOO46 },
  { id: 47, image: TATTOO47 },
  { id: 48, image: TATTOO48 },
  { id: 49, image: TATTOO49 },
  { id: 50, image: TATTOO50 },
  { id: 51, image: TATTOO51 },
  { id: 52, image: TATTOO52 },
  { id: 53, image: TATTOO53 },
  { id: 54, image: TATTOO54 },
  { id: 55, image: TATTOO55 },
  { id: 56, image: TATTOO56 },
  { id: 57, image: TATTOO57 },
  { id: 58, image: TATTOO58 },
  { id: 59, image: TATTOO59 },
  { id: 60, image: TATTOO60 },
  { id: 61, image: TATTOO61 },
  { id: 62, image: TATTOO62 },
  { id: 63, image: TATTOO63 },
  { id: 64, image: TATTOO64 },
  { id: 65, image: TATTOO65 },
  { id: 66, image: TATTOO66 },
  { id: 67, image: TATTOO67 },
  { id: 68, image: TATTOO68 },
  { id: 69, image: TATTOO69 },
  { id: 70, image: TATTOO70 },
  { id: 71, image: TATTOO71 },
  { id: 72, image: TATTOO72 },
  { id: 73, image: TATTOO73 },
  { id: 74, image: TATTOO74 },
  { id: 75, image: TATTOO75 },
  { id: 76, image: TATTOO76 },
  { id: 77, image: TATTOO77 },
  { id: 78, image: TATTOO78 },
  { id: 79, image: TATTOO79 },
  { id: 80, image: TATTOO80 },
  { id: 81, image: TATTOO81 },
  { id: 82, image: TATTOO82 },
  { id: 83, image: TATTOO83 },
  { id: 84, image: TATTOO84 },
  { id: 85, image: TATTOO85 },
  { id: 86, image: TATTOO86 },
  { id: 87, image: TATTOO87 },
  { id: 88, image: TATTOO88 },
  { id: 89, image: TATTOO89 },
  { id: 90, image: TATTOO90 },
  { id: 91, image: TATTOO91 },
  { id: 92, image: TATTOO92 },
  { id: 93, image: TATTOO93 },
  { id: 94, image: TATTOO94 },
  { id: 95, image: TATTOO95 },
  { id: 96, image: TATTOO96 },
  { id: 97, image: TATTOO97 },
  { id: 98, image: TATTOO98 },
  { id: 99, image: TATTOO99 },
  { id: 100, image: TATTOO100 },
  { id: 101, image: TATTOO101},
  { id: 102, image: TATTOO102 },
  { id: 103, image: TATTOO103 },
  { id: 104, image: TATTOO104 },
  { id: 105, image: TATTOO105 },
  { id: 106, image: TATTOO106 },
  { id: 107, image: TATTOO107 },
  { id: 108, image: TATTOO108 },
  { id: 109, image: TATTOO109},
  { id: 110, image: TATTOO110 },
  { id: 111, image: TATTOO111 },
  { id: 112, image: TATTOO112 },
  { id: 1113, image: TATTOO113 },
  { id: 114, image: TATTOO114 },
  { id: 115, image: TATTOO115 },
  { id: 116, image: TATTOO116 },
  { id: 121, image: TATTOO121 },
  { id: 122, image: TATTOO122},
  { id: 123, image: TATTOO123 },
  { id: 124, image: TATTOO124 },
  { id: 125, image: TATTOO125 },
  { id: 126, image: TATTOO126 },
  { id: 127, image: TATTOO127 },
  { id: 128, image: TATTOO128 },
  { id: 129, image: TATTOO129 },
  { id: 130, image: TATTOO130 },
  { id: 131, image: TATTOO131 },
  { id: 132, image: TATTOO132 },
  { id: 133, image: TATTOO133 },
  { id: 134, image: TATTOO134 },
  { id: 135, image: TATTOO135 },
  { id: 136, image: TATTOO136 },
  { id: 137, image: TATTOO137 },
  { id: 138, image: TATTOO138 },
  { id: 139, image: TATTOO139 },
  { id: 140, image: TATTOO140 },
  { id: 141, image: TATTOO141 },
  { id: 142, image: TATTOO142 },
  { id: 143, image: TATTOO143 },
  { id: 144, image: TATTOO144 },
  { id: 145, image: TATTOO145 },
  { id: 146, image: TATTOO146 },
  { id: 147, image: TATTOO147 },
  { id: 148, image: TATTOO148 },
  { id: 175, image: TATTOO175 },

  { id: 149, image: TATTOO149 },
  { id: 150, image: TATTOO150 },
  { id: 151, image: TATTOO151 },
  { id: 152, image: TATTOO152 },
  { id: 153, image: TATTOO153 },
  { id: 154, image: TATTOO154 },
  { id: 155, image: TATTOO155 },
  { id: 156, image: TATTOO156 },
  { id: 157, image: TATTOO157 },
  { id: 158, image: TATTOO158 },
  { id: 159, image: TATTOO159 },
  { id: 160, image: TATTOO160 },
  { id: 161, image: TATTOO161 },
  { id: 162, image: TATTOO162},
  { id: 163, image: TATTOO163 },
  { id: 164, image: TATTOO164 },
  { id: 165, image: TATTOO165 },
  { id: 166, image: TATTOO166 },
  { id: 167, image: TATTOO167 },
  { id: 176, image: TATTOO176 },
  { id: 168, image: TATTOO168 },
  { id: 169, image: TATTOO169 },
  { id: 170, image: TATTOO170 },
  { id: 171, image: TATTOO171 },
  { id: 172, image: TATTOO172 },
  { id: 173, image: TATTOO173 },
  { id: 174, image: TATTOO174 },


];

