import React, { useEffect } from 'react'
import Header from '../header/Header'
import BLOGDETAILS from '../../assets/images/blogdetails.png'
import WATSAPP from '../../assets/images/wp.png'
import FB from '../../assets/images/fb.png'
import TWITTER from '../../assets/images/twitter (2).png'
import INSTA from '../../assets/images/insta (1).png'
import IMG from '../../assets/images/image 4 (1).png'
import Contact from '../contactus/Contact'
import Footer from '../footer/Footer'
import { useNavigate } from 'react-router-dom';


function BlogDetails({ selectedBlog, setSelectedBlog }) {
    const navigate = useNavigate();

    useEffect(() => {
        const savedBlog = localStorage.getItem('selectedBlog');
        if (!selectedBlog && savedBlog) {
            setSelectedBlog(JSON.parse(savedBlog));
        } else if (!selectedBlog) {
            navigate('/Blog');
        } else {
            localStorage.setItem('selectedBlog', JSON.stringify(selectedBlog));
        }
    }, [selectedBlog, setSelectedBlog, navigate]);

    if (!selectedBlog) {
        return null;
    }

    return (
        <div>
            <Header />
            <div className='w-full max-w-[100%] mx-auto px-[3vh] py-[5vh] xl:px-[8vh] xl:py-[10vh] 2xl:max-w-[1440px]'>
                <div className='border-[1px] border-t-[#FFFFFF]'></div>
                {selectedBlog.id === 1 && (
                    <div>
                        <h1 className='adderley font-bold lg:text-[50px] md:text-[30px] text-[20px] text-white mt-4'>
                            LOREM IPSUM IS SIMPLY DUMMY TEXT      </h1>
                        <h3 className='md:text-[20px] text-[11.93px] text-white font-normal'>Blog author name | 03 June 2024</h3>
                        <img src={BLOGDETAILS} alt='blogdetails' className='w-full mt-3' />
                        <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                        </p>
                        <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                        </p>
                        <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                        </p>
                        <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                        </p>
                        <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                        <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <div className='flex justify-between'>
                            <div className='flex gap-4 items-center'>
                                <img src={IMG} alt='blogdetails' className='blackandwhiteimg rounded-[50%] xl:h-[120px] lg:h-[80px] h-[44.46px] xl:w-[120px] lg:w-[80px] w-[44.46px] mt-4' />
                                <div className='mt-4'>
                                    <h1 className='text-[#727272] xl:text-[18px] lg:text-[16px] text-[10px]'>Written By</h1>
                                    <p className='text-white xl:text-[24px] lg:text-[20px] text-[12.17px]'>Arthor Name</p>
                                </div>
                            </div>
                            <div className='flex gap-[12px] mt-5 items-center'>
                                <img src={WATSAPP} alt='whatsapp' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                                <img src={FB} alt='facebook' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                                <img src={TWITTER} alt='twitter' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                                <img src={INSTA} alt='instagram' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                            </div>
                        </div>
                    </div>
                )}
                {selectedBlog.id === 2 && (
                   <div>
                   <h1 className='font-bold lg:text-[50px] md:text-[30px] text-[20px] text-white mt-4'>
                       LOREM IPSUM IS SIMPLY DUMMY TEXT2      </h1>
                   <h3 className='md:text-[20px] text-[11.93px] text-white font-normal'>Blog author name | 03 June 2024</h3>
                   <img src={BLOGDETAILS} alt='blogdetails' className='w-full mt-3' />
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <div className='flex justify-between'>
                       <div className='flex gap-4 items-center'>
                           <img src={IMG} alt='blogdetails' className='blackandwhiteimg rounded-[50%] xl:h-[120px] lg:h-[80px] h-[44.46px] xl:w-[120px] lg:w-[80px] w-[44.46px] mt-4' />
                           <div className='mt-4'>
                               <h1 className='text-[#727272] xl:text-[18px] lg:text-[16px] text-[10px]'>Written By</h1>
                               <p className='text-white xl:text-[24px] lg:text-[20px] text-[12.17px]'>Arthor Name</p>
                           </div>
                       </div>
                       <div className='flex gap-[12px] mt-5 items-center'>
                           <img src={WATSAPP} alt='whatsapp' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={FB} alt='facebook' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={TWITTER} alt='twitter' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={INSTA} alt='instagram' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                       </div>
                   </div>
               </div>
                )}
                   {selectedBlog.id === 3 && (
                   <div>
                   <h1 className='font-bold lg:text-[50px] md:text-[30px] text-[20px] text-white mt-4'>
                       LOREM IPSUM IS SIMPLY DUMMY TEXT3      </h1>
                   <h3 className='md:text-[20px] text-[11.93px] text-white font-normal'>Blog author name | 03 June 2024</h3>
                   <img src={BLOGDETAILS} alt='blogdetails' className='w-full mt-3' />
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <div className='flex justify-between'>
                       <div className='flex gap-4 items-center'>
                           <img src={IMG} alt='blogdetails' className='blackandwhiteimg rounded-[50%] xl:h-[120px] lg:h-[80px] h-[44.46px] xl:w-[120px] lg:w-[80px] w-[44.46px] mt-4' />
                           <div className='mt-4'>
                               <h1 className='text-[#727272] xl:text-[18px] lg:text-[16px] text-[10px]'>Written By</h1>
                               <p className='text-white xl:text-[24px] lg:text-[20px] text-[12.17px]'>Arthor Name</p>
                           </div>
                       </div>
                       <div className='flex gap-[12px] mt-5 items-center'>
                           <img src={WATSAPP} alt='whatsapp' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={FB} alt='facebook' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={TWITTER} alt='twitter' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={INSTA} alt='instagram' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                       </div>
                   </div>
               </div>
                )}
                   {selectedBlog.id === 4 && (
                   <div>
                   <h1 className='font-bold lg:text-[50px] md:text-[30px] text-[20px] text-white mt-4'>
                       LOREM IPSUM IS SIMPLY DUMMY TEXT4</h1>
                   <h3 className='md:text-[20px] text-[11.93px] text-white font-normal'>Blog author name | 03 June 2024</h3>
                   <img src={BLOGDETAILS} alt='blogdetails' className='w-full mt-3' />
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <div className='flex justify-between'>
                       <div className='flex gap-4 items-center'>
                           <img src={IMG} alt='blogdetails' className='blackandwhiteimg rounded-[50%] xl:h-[120px] lg:h-[80px] h-[44.46px] xl:w-[120px] lg:w-[80px] w-[44.46px] mt-4' />
                           <div className='mt-4'>
                               <h1 className='text-[#727272] xl:text-[18px] lg:text-[16px] text-[10px]'>Written By</h1>
                               <p className='text-white xl:text-[24px] lg:text-[20px] text-[12.17px]'>Arthor Name</p>
                           </div>
                       </div>
                       <div className='flex gap-[12px] mt-5 items-center'>
                           <img src={WATSAPP} alt='whatsapp' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={FB} alt='facebook' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={TWITTER} alt='twitter' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={INSTA} alt='instagram' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                       </div>
                   </div>
               </div>
                )}
                   {selectedBlog.id === 5 && (
                   <div>
                   <h1 className='font-bold lg:text-[50px] md:text-[30px] text-[20px] text-white mt-4'>
                       LOREM IPSUM IS SIMPLY DUMMY TEXT5</h1>
                   <h3 className='md:text-[20px] text-[11.93px] text-white font-normal'>Blog author name | 03 June 2024</h3>
                   <img src={BLOGDETAILS} alt='blogdetails' className='w-full mt-3' />
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <div className='flex justify-between'>
                       <div className='flex gap-4 items-center'>
                           <img src={IMG} alt='blogdetails' className='blackandwhiteimg rounded-[50%] xl:h-[120px] lg:h-[80px] h-[44.46px] xl:w-[120px] lg:w-[80px] w-[44.46px] mt-4' />
                           <div className='mt-4'>
                               <h1 className='text-[#727272] xl:text-[18px] lg:text-[16px] text-[10px]'>Written By</h1>
                               <p className='text-white xl:text-[24px] lg:text-[20px] text-[12.17px]'>Arthor Name</p>
                           </div>
                       </div>
                       <div className='flex gap-[12px] mt-5 items-center'>
                           <img src={WATSAPP} alt='whatsapp' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={FB} alt='facebook' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={TWITTER} alt='twitter' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={INSTA} alt='instagram' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                       </div>
                   </div>
               </div>
                )}
                   {selectedBlog.id === 6 && (
                   <div>
                   <h1 className='font-bold lg:text-[50px] md:text-[30px] text-[20px] text-white mt-4'>
                       LOREM IPSUM IS SIMPLY DUMMY TEXT6</h1>
                   <h3 className='md:text-[20px] text-[11.93px] text-white font-normal'>Blog author name | 03 June 2024</h3>
                   <img src={BLOGDETAILS} alt='blogdetails' className='w-full mt-3' />
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <div className='flex justify-between'>
                       <div className='flex gap-4 items-center'>
                           <img src={IMG} alt='blogdetails' className='blackandwhiteimg rounded-[50%] xl:h-[120px] lg:h-[80px] h-[44.46px] xl:w-[120px] lg:w-[80px] w-[44.46px] mt-4' />
                           <div className='mt-4'>
                               <h1 className='text-[#727272] xl:text-[18px] lg:text-[16px] text-[10px]'>Written By</h1>
                               <p className='text-white xl:text-[24px] lg:text-[20px] text-[12.17px]'>Arthor Name</p>
                           </div>
                       </div>
                       <div className='flex gap-[12px] mt-5 items-center'>
                           <img src={WATSAPP} alt='whatsapp' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={FB} alt='facebook' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={TWITTER} alt='twitter' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={INSTA} alt='instagram' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                       </div>
                   </div>
               </div>
                )}
                   {selectedBlog.id === 7 && (
                   <div>
                   <h1 className='font-bold lg:text-[50px] md:text-[30px] text-[20px] text-white mt-4'>
                       LOREM IPSUM IS SIMPLY DUMMY TEXT7      </h1>
                   <h3 className='md:text-[20px] text-[11.93px] text-white font-normal'>Blog author name | 03 June 2024</h3>
                   <img src={BLOGDETAILS} alt='blogdetails' className='w-full mt-3' />
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <div className='flex justify-between'>
                       <div className='flex gap-4 items-center'>
                           <img src={IMG} alt='blogdetails' className='blackandwhiteimg rounded-[50%] xl:h-[120px] lg:h-[80px] h-[44.46px] xl:w-[120px] lg:w-[80px] w-[44.46px] mt-4' />
                           <div className='mt-4'>
                               <h1 className='text-[#727272] xl:text-[18px] lg:text-[16px] text-[10px]'>Written By</h1>
                               <p className='text-white xl:text-[24px] lg:text-[20px] text-[12.17px]'>Arthor Name</p>
                           </div>
                       </div>
                       <div className='flex gap-[12px] mt-5 items-center'>
                           <img src={WATSAPP} alt='whatsapp' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={FB} alt='facebook' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={TWITTER} alt='twitter' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={INSTA} alt='instagram' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                       </div>
                   </div>
               </div>
                )}
                   {selectedBlog.id === 8 && (
                   <div>
                   <h1 className='font-bold lg:text-[50px] md:text-[30px] text-[20px] text-white mt-4'>
                       LOREM IPSUM IS SIMPLY DUMMY TEXT8</h1>
                   <h3 className='md:text-[20px] text-[11.93px] text-white font-normal'>Blog author name | 03 June 2024</h3>
                   <img src={BLOGDETAILS} alt='blogdetails' className='w-full mt-3' />
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                   </p>
                   <p className='text-white md:text-[24px] text-[20px] font-normal mt-4 text-justify'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                   <div className='flex justify-between'>
                       <div className='flex gap-4 items-center'>
                           <img src={IMG} alt='blogdetails' className='blackandwhiteimg rounded-[50%] xl:h-[120px] lg:h-[80px] h-[44.46px] xl:w-[120px] lg:w-[80px] w-[44.46px] mt-4' />
                           <div className='mt-4'>
                               <h1 className='text-[#727272] xl:text-[18px] lg:text-[16px] text-[10px]'>Written By</h1>
                               <p className='text-white xl:text-[24px] lg:text-[20px] text-[12.17px]'>Arthor Name</p>
                           </div>
                       </div>
                       <div className='flex gap-[12px] mt-5 items-center'>
                           <img src={WATSAPP} alt='whatsapp' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={FB} alt='facebook' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={TWITTER} alt='twitter' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                           <img src={INSTA} alt='instagram' className='md:h-[51px] md:w-[51px]  h-[23.44px] w-[23.44px] cursor-pointer' />
                       </div>
                   </div>
               </div>
                )}
            </div>
            <div className=' bg-[#000000] md:py-[10vh] py-[4vh] max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh] px-[3vh]'>
                <Contact />
            </div>
        </div>
    )
}

export default BlogDetails