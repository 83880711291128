import React, { useEffect, useState } from 'react';
import CUSTOMER_IMG from '../assets/images/home/customer_say.svg';
import VECTOR_SVG from '../assets/images/Vector.svg';

const slidesData = [
  {
    id: 1,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    name: "Anandbabu Shetty",
    title: "Marketing Manager at Shree Electronics",
    image: CUSTOMER_IMG,
  },
  {
    id: 2,
    text: "Another dummy text example for the carousel, showing different content in the second slide.",
    name: "John Doe",
    title: "Product Manager at XYZ Corporation",
    image: CUSTOMER_IMG,
  },
  {
    id: 3,
    text: "More dummy text for the third slide, showcasing yet another variation of the content.",
    name: "Jane Smith",
    title: "CEO of ABC Inc.",
    image: CUSTOMER_IMG,
  },
  {
    id: 4,
    text: "Final slide text to complete the dynamic content carousel example.",
    name: "Michael Johnson",
    title: "CTO at TechWorld",
    image: CUSTOMER_IMG,
  },
];

function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex === slidesData.length - 1 ? 0 : prevIndex + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div className='carsoul bg-[#000000]'>
        <div
          className="flex transition-transform duration-1000"
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          {slidesData.map((slide) => (
            <div key={slide.id} className="bg-[#000000] px-6 pb-6 pt-[3vh] min-w-full">
              <img src={VECTOR_SVG} alt="Vector Icon" className="w-[67.53px] h-[58px]" />
              <p className="text-white md:text-[20px] text-[18.01px] font-normal">
                {slide.text}
              </p>
              <div className="mt-5 flex gap-3 items-center">
                <img src={slide.image} alt={slide.name} className="w-[80px] h-[80px]" />
                <div>
                  <p className="text-[#4FF8A7] md:text-[22px] text-[12.17px] font-semibold">{slide.name}</p>
                  <p className="text-white md:text-[18px] text-[11.6px]">{slide.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center gap-2 mt-4">
        {slidesData.map((_, index) => (
          <div
            key={index}
            className={`h-[5px] w-[15px] ${activeIndex === index ? 'bg-[#4FF8A7]' : 'bg-[#215540]'}`} />
        ))}
      </div>
    </div>
  );
}

export default Carousel;
