import React,{useState} from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from '../components/homepage/HomePage'
import AboutMainPage from '../components/about/AboutMainPage'
import ServicesMainPage from '../components/services/ServicesMainPage'
import FlashTattoo from '../components/flashtattoo/FlashTattoo'
import OurWork from '../components/ourwork/OurWork'
import Blog from '../components/blog/Blog'
import BlogDetails from '../components/blog/BlogDetails'
import ContactMainPage from '../components/contactus/ContactMainPage'
import Footer from '../components/footer/Footer'

function MainRoute() {
  const [selectedBlog, setSelectedBlog] = useState(null);

  return (
    <div>
        <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/About" element={<AboutMainPage/>} />
        <Route path="/Service" element={<ServicesMainPage/>} />
        <Route path="/Flash_tattoos" element={<FlashTattoo/>} />
        <Route path="/Ourwork" element={<OurWork/>} />
        <Route path="/Blog" element={<Blog setSelectedBlog={setSelectedBlog}/>} />
        <Route path="/Blog_details" element={<BlogDetails selectedBlog={selectedBlog} setSelectedBlog={setSelectedBlog}/>} />
        <Route path="/Contact" element={<ContactMainPage/>} />
        </Routes>
        <Footer/>

    </div>
    
  )
}

export default MainRoute