import React from 'react'
import LOGO from '../../assets/images/home/logo2.png'
import ICON1 from '../../assets/images/home/social_media/wp.svg'
import ICON2 from '../../assets/images/home/social_media/fb.svg'
import ICON3 from '../../assets/images/home/social_media/twitter.svg'
import ICON4 from '../../assets/images/home/social_media/insta.svg'

function Footer() {
    return (
        <div className='bg-[#E3E0E0] w-[100%]'>
            <div className="grid xl:grid-cols-8 lg:grid-cols-4   gap-9 mt-7 max-w-1440 lg:w-[100%]  mx-auto xl:px-[4vh] px-[3vh] py-6">
                <div className="col-span-3">
                    <img src={LOGO} alt='logo' className='md:w-[102.77px] w-[69.66px] md:h-[77.08px] h-[52.25px]' />
                    <p className='font-normal md:text-[24px] text-[18.1px]'>We are a premier tattoo studio in
                        Ahmedabad, tattooing high-
                        quality art & personalized designs.
                        We prioritize both our clients &
                        the art we create.</p>
                    <div className='flex gap-3 mt-4'>
                        <img src={ICON1} alt='ICON1' className='md:w-[51.1px] w-[30px] md:h-[51.1px] h-[30px]' />
                        <img src={ICON2} alt='ICON2' className='md:w-[51.1px] w-[30px] md:h-[51.1px] h-[30px]' />
                        <img src={ICON4} alt='ICON4' className='md:w-[51.1px] w-[30px] md:h-[51.1px] h-[30px]' />
                    </div>
                </div>
               
                <div className="col-span-3 mt-7">
                    <h1 className='text-[30px] font-bold'>Hours of Operation</h1>
                    <p className='font-normal md:text-[24px] text-[18.01px]'>MONDAY To SUNDAY <br />
                        12:30pm to 07:30pm</p>
                    <h1 className='text-[30px] font-bold'>Location</h1>
                    <p className='font-normal md:text-[24px] text-[18.01px]'>Rudra Squre,<br /> 36-37/SF, Judges Bungalow Cross Rd,<br /> above Q Land Pan & Gift Shop, <br />Bodakdev, Ahmedabad, Gujarat 380054</p>
                </div>
                <div className="col-span-2 mt-7 xl:ml-[-90px]">
                    <h1 className='text-[30px] font-bold'>Contact</h1>
                    <p className='font-normal md:text-[24px] text-[18.01px]'>Call: +91 99745 33366 </p>
                    <p className='font-normal md:text-[24px] text-[18.01px]'> Mail:robinstattoo1@gmail.com </p>
                </div>
            </div>

            <div className='bg-[#CBCBCB] py-2'>
                <p className='md:text-[18px] text-[9.63px] max-w-1440 w-[90%] mx-auto'>Copyright by Robins Tattoo</p>
            </div>
        </div>
    )
}

export default Footer