import React, { useState } from 'react'
import Header from '../header/Header'
import Details from './details/Details'
import VIDEO from '../../assets/images/home/video.png'
// import VIDEOPLAY from '../../assets/images/home/Robins_Trailer.png'
import Aboutus from '../about/Aboutus'
import Services from '../services/Services'
import OurArtist from '../ourartist/OurArtist'
import OurWorkGallery from '../ourwork/OurWorkGallery'
import Contact from '../contactus/Contact'
import RIGHTARROW from '../../assets/images/rightarrow.png'
import { useNavigate } from "react-router-dom";
import Carousel from '../../commonComponents/Carousel'
import IMG from '../../assets/images/home/banner/02.png'
import VIDEOPLAY from '../../assets/images/home/Robins_Trailer.mp4'
function HomePage() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsVideoPlaying(true);
  }


  return (
    <div>

      <div className='banner 2xl:h-[962px] 1xl:h-[961px] xl:h-[950px] lg:h-[700px] md:h-[550px] h-[243px] relative'>
        <Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
        <button className={`bg-[#4FF8A7] h-[17.96px] w-[64.75px] text-[5.99px] block md:hidden absolute left-[23%] top-[68%] ${isMenuOpen ? 'hidden' : 'block'}`}>
          Visit Studio
        </button>
      </div>
      <div>
        <Details />
        <div>
          {isVideoPlaying ? (
            <video
              src={VIDEOPLAY}
              controls
              autoPlay
              className='w-full h-full'
              onClick={() => setIsVideoPlaying(false)}
            />
          ) : (
            <img
              src={VIDEO}
              alt='video'
              className='w-full h-full cursor-pointer'
              onClick={handlePlayClick}
            />
          )}
        </div>
      </div>
      <div className="w-[100%] bg-[#000000]">
        <div className='max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh]  px-[2vh]'>
          <Aboutus />
        </div>
      </div>

      <div className='max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh]  px-[2vh]'>
        <Services />
      </div>
      <div className="w-[100%] bg-[#000000]">
        <div className='max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh]  px-[2vh] py-[50px]'>
          <OurArtist />
        </div>
      </div>
      <div className='max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh]  px-[2vh]'>
        <div className='xl:mt-[10vh] mt-[7vh]'>
          <div className='border-[1px] border-t-[#FFFFFF]'></div>
          <div>
            <div className='flex justify-between items-center '>
              <h1 className='adderley md:text-[50px] text-[20px] font-bold text-white mt-4 pt-[20px]'>OUR WORK GALLERY</h1>
              <div className='flex gap-2 justify-center items-center cursor-pointer' onClick={() => navigate("/Ourwork")}>
                <p className='Outfit text-[#4FF8A7] md:text-[24px] text-[12.28px] ' style={{ fontWeight: 400 }}>See more</p>
                <img src={RIGHTARROW} alt='right-arrow' className='md:w-[17px] w-[8.7px] md:h-[12.14px] h-[6.21px]' />
              </div>
            </div>
            <OurWorkGallery />
          </div>
        </div>
      </div>
      <div className='w-full bg-[#000000]'>
        <div className='max-w-1440 lg:w-[100%]  mx-auto xl:px-[8vh]  px-[2vh] py-8 2xl:py-16'>
          <div className='border-t border-[#FFFFFF]'></div>
          <h1 className='adderley font-bold text-3xl md:text-4xl lg:text-5xl text-white mt-4 pt-2'>
            YOUR VISION, OUR INK-THE ROBIN'S CONCEPT
          </h1>
          {/* Add gap-y for mobile */}
          <div className="lg:grid lg:grid-cols-3 gap-6 mt-7 flex flex-col gap-y-6">
            {/* Card 1 */}
            <div className="bg-[#0f0f0f] p-6 rounded-lg shadow-lg">
              <div className="card-body ">
                <h2 className='adderley text-white text-2xl md:text-3xl font-bold tracking-tight'>
                  THE BIRTH OF AN IDEA
                </h2>
                <p className='Outfit text-white text-base md:text-lg font-normal mb-3 text-justify'>
                  Step into our studio, where your dreams become the canvas. Our expert Inkster listens intently, crafting a clear vision together, ensuring every detail of your tattoo is perfectly understood.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-[#0f0f0f] p-6 rounded-lg shadow-lg">
              <div className="card-body">
                <h2 className='adderley text-white text-2xl md:text-3xl font-bold tracking-tight'>
                  FROM MIND TO PAPER
                </h2>
                <p className='text-white text-base md:text-lg font-normal mb-3 text-justify'>
                  Watch as your idea transforms into a sketch, capturing your essence. Review the design with our artist, giving feedback and tweaking until it embodies your imagination perfectly.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="bg-[#0f0f0f] p-6 rounded-lg shadow-lg">
              <div className="card-body">
                <h2 className='adderley text-white text-2xl md:text-3xl font-bold tracking-tight'>
                  INK MEETS SKIN
                </h2>
                <p className='text-white text-base md:text-lg font-normal mb-3 text-justify'>
                  With your design refined, the tattooing begins. Feel the steady rhythm of the needle as your vision comes to life, becoming a unique piece of art etched onto your skin.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-[#0f0f0f] w-full'>
        <div className='  w-[100%] bg-[#0F0F0F]  max-w-1440 lg:w-[100%] mx-auto xl:px-[8vh] px-[3vh] 2xl:py-[7vh] py-[4vh]'>
          <div className='border-[1px] border-t-[#FFFFFF]'></div>
          <h1 className='adderley font-bold lg:text-[50px] md:[35px] text-[20px] text-white mt-4'>OUR CUSTOMER SAYS</h1>
          <Carousel />
        </div>
        </div>

        <div className="contact bg-[#000000]">
          <div className='md:py-[10vh] py-[4vh] max-w-1440 lg:w-[100%] mx-auto xl:px-[8vh] px-[3vh]'>
            <Contact />
          </div>
        </div>
      </div>
      </div>
      )
}
      export default HomePage