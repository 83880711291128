import React from 'react'
import Header from '../header/Header'
import { useNavigate } from 'react-router-dom';

import BLOG_IMG from '../../assets/images/blog-img.png'
import RIGHTARROW from '../../assets/images/rightarrow.png'
import Contact from '../contactus/Contact';

function Blog({ setSelectedBlog }) {
  const navigate = useNavigate();

  const blogs = [
    {
      id: 1,
      author: 'Rahul Upadhyay',
      role: 'Partner/Co Founder',
      img: BLOG_IMG,
    },
    {
      id: 2,
      author: 'Rahul Upadhyay',
      role: 'Partner/Co Founder',
      img: BLOG_IMG,
    },
    {
      id: 3,
      author: 'Rahul Upadhyay',
      role: 'Partner/Co Founder',
      img: BLOG_IMG,
    },
    {
      id: 4,
      author: 'Rahul Upadhyay',
      role: 'Partner/Co Founder',
      img: BLOG_IMG,
    },
    {
      id: 5,
      author: 'Rahul Upadhyay',
      role: 'Partner/Co Founder',
      img: BLOG_IMG,
    },
    {
      id: 6,
      author: 'Rahul Upadhyay',
      role: 'Partner/Co Founder',
      img: BLOG_IMG,
    },
    {
      id: 7,
      author: 'Rahul Upadhyay',
      role: 'Partner/Co Founder',
      img: BLOG_IMG,
    },
    {
      id: 8,
      author: 'Rahul Upadhyay',
      role: 'Partner/Co Founder',
      img: BLOG_IMG,
    },
  ];
  const handleLearnMore = (blog) => {
    setSelectedBlog(blog);
    navigate('/Blog_details');
  };
  return (
    <div>
      <Header />
      <div className='bg-banner2 xl:h-[370px] flex justify-center items-center'>
        <h1 className='adderley text-white md:text-[80px] text-[50px] font-bold'>blog</h1>
      </div>

      <div className='2xl:py-[10vh] py-[5vh] max-w-1440 lg:w-[100%] mx-auto xl:px-[8vh] px-[2vh]'>
        <div className='border-[1px] border-t-[#FFFFFF]'></div>
        <h1 className='adderley font-bold lg:text-[50px] md:text-[35px] text-[20px] text-white mt-6'>CHECK OUT OUR LATEST BLOGS</h1>

        <div className='md:grid md:grid-cols-2 xl:gap-8 gap-6 mt-7'>
          {blogs.map((blog) => (
            <div key={blog.id} className='bg-[#000000] col-span-1 md:mb-0 mb-4'>
              <img src={blog.img} alt='blog-img' className='w-full' />
              <h1 className='text-white font-bold md:text-[28px] text-[16.7px] px-4 pt-4 pb-2'>{blog.author}</h1>
              <div className='flex justify-between px-4 pb-4'>
                <h1 className='text-white md:text-[20px] text-[11.93px] font-normal'>{blog.role}</h1>
                <div className='flex gap-2 justify-center items-center cursor-pointer' onClick={() => handleLearnMore(blog)}>
                  <p className='text-[#4FF8A7] md:text-[20px] text-[11.93px]' style={{ fontWeight: 400 }}>See more</p>
                  <img src={RIGHTARROW} alt='right-arrow' className='md:w-[17px] w-[10.14px] md:h-[12.14px] text-[7.24px]' />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='w-[100%] bg-[#000000]'>
        <div className='bg-[#000000] 2xl:py-[10vh] py-[5vh] max-w-1440 lg:w-[100%] mx-auto xl:px-[8vh] px-[2vh]'>
          <Contact />
        </div>
      </div>
    </div>
  );
}

export default Blog